<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="flex-def flex-zCenter flex-cCenter">
      <el-card class="simple-box">
        <div style="margin-bottom: 4rem;font-weight: 600;font-size: 1.6rem;text-align: center">账号资料设置</div>
        <el-form label-position="left" label-width="6rem" :model="form">
          <el-form-item label="登陆账号：">
            <div class="flex-def flex-cCenter flex-zBetween">
              <span>{{ vuex_account.username }}</span>
              <span class="y-desc" style="color: red;margin-left: .5rem">{{ statusTag }}</span>
            </div>
          </el-form-item>
          <el-form-item label="新密码：" prop="password">
            <el-input type="password" show-password v-model="form.password" placeholder="请输入您的登陆密码"></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="re_password">
            <el-input type="password" show-password v-model="form.re_password" placeholder="请再次输入您的登陆密码"></el-input>
          </el-form-item>
        </el-form>
        <div style="margin-top: 4rem">
          <el-button @click="submit" size="large" type="primary" style="width: 100%">保存</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>

import SimpleHeader from "@/components/layouts/SimpleHeader.vue";
import {AccountStatusTag} from "@/common/mod/account";
import {accountLogout} from "@/common/util";
export default {
  name: "info",
  components: {SimpleHeader},
  data(){
    return{
      form:{
        password:"",
        re_password:"",
      }
    }
  },
  computed:{
    statusTag(){
      return  AccountStatusTag(this.vuex_account.status);
    },
  },
  methods:{
    submit(){
      this.$u.api.account.resetPassword(this.form).then(()=>{
        this.$message.success("操作成功，请重新登录")
        accountLogout()
      })
    }
  }
}
</script>

<style scoped>
  .simple-box{
    padding: 4rem;
    background-color: #FFFFFF;
    min-width: 20rem;
    margin-top: 4rem;
  }
</style>